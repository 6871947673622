<!--
 * @Description: 内容发布--资讯帖子--分类配置Form页面
 * @Author: 小广
 * @Date: 2019-06-21 11:24:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-13 10:28:42
 -->

<template>
  <div class="InfoPostCategoryForm-wrapper">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block>
          <el-form-item
            label="分类名称"
            :rules="[
              { required: true, message: '请输入名称', trigger: 'blur' }
            ]"
            prop="value"
          >
            <v-input
              v-model="form.value"
              placeholder="请输入名称"
              :maxlength="10"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="所属App"
            :rules="[
              { required: true, message: '请选择所属App', trigger: 'change' }
            ]"
            prop="appType"
          >
            <v-select
              v-model="form.appType"
              :options="appTypeOps"
              :width="width"
              @change="appTypeChange"
            />
          </el-form-item>
          <el-form-item
            label="分类层级"
            :rules="[
              { required: true, message: '请选择分类层级', trigger: 'change' }
            ]"
            prop="codeLevel"
          >
            <v-select
              v-model="form.codeLevel"
              :options="codeLevelOps"
              :width="width"
              disabled
            />
          </el-form-item>
          <el-form-item
            label="所属上级分类"
            :rules="[
              { required: true, message: '请选择所属上级分类', trigger: 'change' }
            ]"
            prop="superid"
          >
            <v-select
              v-model="form.superid"
              :options="superLevelOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            label="层级"
            :rules="[
              { required: true, message: '请选择层级', trigger: 'change' }
            ]"
            prop="hierarchy"
          >
            <v-select
              v-model="form.hierarchy"
              :options="hierarchyOps"
              :width="width"
            />
          </el-form-item>
          <el-form-item
            v-if="form.hierarchy !== 1"
            label="所属组织"
            :rules="[
              { required: true, message: '请选择所属组织', trigger: 'change' }
            ]"
            prop="regionId"
          >       
            <v-select2
              :width="180"
              placeholder="请选择所属组织"
              v-model="form.regionId"
              v-bind="regionParams"
              @onChange="regionChange"
            ></v-select2>
          </el-form-item>
          <el-form-item label="备注" >
            <v-textarea
            v-model="form.remark"
            placeholder="请填写备注"
            :maxlength="10"
            ></v-textarea>
          </el-form-item>
          <el-form-item
            v-if="!isAdd"
            label="状态"
            :rules="[
              { required: true, message: '请选择状态', trigger: 'change' }
            ]"
            prop="status"
          >
            <v-select
              v-model="form.status"
              :options="statusOps"
              :width="width"
            />
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { editCategoryURL, queryCategoryURL, getFirstLevelListURL } from './api'
import { statusOps, codeLevelOps, appTypeOps } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'
import { communityParams, regionParams } from 'common/select2Params'

export default {
  name: '',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      width: 182,
      statusOps: statusOps(),
      codeLevelOps: codeLevelOps(),
      appTypeOps: appTypeOps(),
      superLevelOps: [],
      form: {
        id: undefined,
        value: '',
        remark: '',
        superid: undefined,
        status: 1,
        codeLevel: 2,
        appType: undefined,
        hierarchy: undefined, // 层级 1: 平台 2: 公司
        regionId: '',
        regionName: ''
      },
      submitConfig: {},
      isAdd: false,
      appTypeEcho: false,
      hierarchyOps: [
        {
          text: '平台',
          value: 1
        },
        {
          text: '公司',
          value: 2
        },
      ],
      communityParams,
      regionParams,
      userType: ''
    }
  },
  created () {
    let userType = this.$store.state.userInfo.userType
    this.userType = userType
    if (this.userType !== '106' && this.userType !== '100') {
      this.hierarchyOps = [
        {
          text: '公司',
          value: 2
        },
      ]
    }
    const { id } = this.$route.query
    this.isAdd = !id
    this.submitConfig = {
      queryUrl: `${queryCategoryURL}/${id}`,
      submitUrl: this.isAdd ? editCategoryURL : `${editCategoryURL}/${id}`,
      submitMethod: this.isAdd ? 'post' : 'put'
    }
    let title = this.isAdd ? '新增分类' : '编辑分类'
    if (this.isAdd) {
      this.form.appType = 0
    }
    // 设置title
    this.$setBreadcrumb(title)
  },

  mounted () {
    if (!this.isAdd) {
      this.appTypeEcho = true
      this.$refs.formPanel.getData()
    }
    // 询问了产品杨阔，这个分类只允许添加二级分类，二级分类所属上级superLevelOps，是一级分类，codeLevel传1
    this.getFirstLevelList(1, this.isAdd)
  },
  methods: {
    update (data) {
      data.codeLevel = parseInt(data.codeLevel)
      Object.keys(this.form).forEach(key => {
        this.form[key] = data[key]
      })
    },
    submitBefore (data) {
      if (this.isAdd) {
        delete data.id
        delete data.status
      }
      if (this.form.hierarchy === 1) {
        data.regionName = ''
        data.regionId = ''
      } else {
        if (data.regionName === '') {
          data.regionId = ''
        }
      }
      return true
    },

    appTypeChange (val) {
      this.getFirstLevelList(1, !this.appTypeEcho)
      if (this.appTypeEcho) {
        this.appTypeEcho = false
      }
    },

    // 获取上级分类 getDefault是否取第一个为默认值
    async getFirstLevelList (codeLevel, getDefault) {
      let getParams = {
        params: {
          codeLevel: codeLevel,
          appType: this.form.appType
        }
      }

      let res = await this.$axios.get(getFirstLevelListURL, getParams)
      const { data, status } = res
      let ops = []
      if (status === 100) {
        ops = data.map(val => {
          return {
            text: val.value,
            value: val.id
          }
        }) || []
      }
      if (getDefault) {
        this.form.superid = ops.length ? ops[0].value : undefined
      } else {
        if (this.form.superid && this.form.superid.length) {
          // 融应用兄弟连 B APP2-954 【管理后台】【资讯管理】编辑资讯优化
          // 判断下回显时，对应的type是否在typeList里
          let findItem = ops.find(item => item.value === this.form.superid)
          if (!findItem) {
            ops.push({
              text: this.form.superCodeword,
              value: this.form.superid
            })
          }
        }
      }
      this.superLevelOps = ops
    },
    // 租户选择
    regionChange (user) {
      if (user) {
        this.form.regionId = user.id
        this.form.regionName = user.name
      } else {
        this.form.regionId = ''
        this.form.regionName = ''
      }
    }
  }
}
</script>
