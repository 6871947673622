var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InfoPostCategoryForm-wrapper" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "value",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入名称",
                          maxlength: 10,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "value", $$v)
                          },
                          expression: "form.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属App",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属App",
                            trigger: "change",
                          },
                        ],
                        prop: "appType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.appTypeOps, width: _vm.width },
                        on: { change: _vm.appTypeChange },
                        model: {
                          value: _vm.form.appType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "appType", $$v)
                          },
                          expression: "form.appType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "分类层级",
                        rules: [
                          {
                            required: true,
                            message: "请选择分类层级",
                            trigger: "change",
                          },
                        ],
                        prop: "codeLevel",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.codeLevelOps,
                          width: _vm.width,
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.codeLevel,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "codeLevel", $$v)
                          },
                          expression: "form.codeLevel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属上级分类",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属上级分类",
                            trigger: "change",
                          },
                        ],
                        prop: "superid",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.superLevelOps, width: _vm.width },
                        model: {
                          value: _vm.form.superid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "superid", $$v)
                          },
                          expression: "form.superid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "层级",
                        rules: [
                          {
                            required: true,
                            message: "请选择层级",
                            trigger: "change",
                          },
                        ],
                        prop: "hierarchy",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.hierarchyOps, width: _vm.width },
                        model: {
                          value: _vm.form.hierarchy,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "hierarchy", $$v)
                          },
                          expression: "form.hierarchy",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.hierarchy !== 1
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属组织",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属组织",
                                trigger: "change",
                              },
                            ],
                            prop: "regionId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  width: 180,
                                  placeholder: "请选择所属组织",
                                },
                                on: { onChange: _vm.regionChange },
                                model: {
                                  value: _vm.form.regionId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "regionId", $$v)
                                  },
                                  expression: "form.regionId",
                                },
                              },
                              "v-select2",
                              _vm.regionParams,
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注" } },
                    [
                      _c("v-textarea", {
                        attrs: { placeholder: "请填写备注", maxlength: 10 },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  !_vm.isAdd
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "状态",
                            rules: [
                              {
                                required: true,
                                message: "请选择状态",
                                trigger: "change",
                              },
                            ],
                            prop: "status",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { options: _vm.statusOps, width: _vm.width },
                            model: {
                              value: _vm.form.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "status", $$v)
                              },
                              expression: "form.status",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }